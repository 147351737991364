import React from 'react'

const ResultsMobile = ({timer, runTimer, results}) => {
  return (
    <div className='results-section mobile' >

          {runTimer && <p className='timer'>{timer}</p>}
          {results && <p className='results-winner'> {results.winner}</p>}
          {results && <p className='results-message'>{results.msg}</p>}
      
    </div>
  )
}

export default ResultsMobile
